import { SVGProps } from 'react';

function Youtube({
  fill = 'var(--neutral-0)',
  width = 20,
  height = 20,
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_834_21260"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={25}
        height={24}
      >
        <path d="M24.3335 0H0.333496V24H24.3335V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_834_21260)">
        <path
          d="M23.832 6.22343C23.556 5.19083 22.7427 4.37761 21.7101 4.10157C19.8385 3.6001 12.3335 3.6001 12.3335 3.6001C12.3335 3.6001 4.82846 3.6001 2.95686 4.10157C1.9242 4.37761 1.11104 5.19083 0.834941 6.22343C0.333496 8.09506 0.333496 12.0001 0.333496 12.0001C0.333496 12.0001 0.333496 15.9051 0.834941 17.7767C1.11104 18.8093 1.9242 19.6226 2.95686 19.8986C4.82846 20.4001 12.3335 20.4001 12.3335 20.4001C12.3335 20.4001 19.8385 20.4001 21.7101 19.8986C22.7427 19.6226 23.556 18.8093 23.832 17.7767C24.3335 15.9051 24.3335 12.0001 24.3335 12.0001C24.3335 12.0001 24.3335 8.09506 23.832 6.22343ZM9.9335 15.6001V8.4001L16.1686 12.0002L9.9335 15.6001Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

export default Youtube;
